import React from 'react';

const ArrowIcon = () => {
	return (
		<svg className='arrow-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21.881 17.51'>
			<g>
				<path d='M0 9.755v-2h20.017v2z'/>
				<path d='M19.053 8.755l-7.342-7.341 1.414-1.415 7.342 7.341 1.414 1.414-8.756 8.755-1.414-1.414z'/>
			</g>
		</svg>
	);
};

export default ArrowIcon;