// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/article-page.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-articles-library-page-tsx": () => import("./../../../src/templates/articles-library-page.tsx" /* webpackChunkName: "component---src-templates-articles-library-page-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-front-page-tsx": () => import("./../../../src/templates/front-page.tsx" /* webpackChunkName: "component---src-templates-front-page-tsx" */),
  "component---src-templates-news-article-page-tsx": () => import("./../../../src/templates/news-article-page.tsx" /* webpackChunkName: "component---src-templates-news-article-page-tsx" */),
  "component---src-templates-news-library-page-tsx": () => import("./../../../src/templates/news-library-page.tsx" /* webpackChunkName: "component---src-templates-news-library-page-tsx" */),
  "component---src-templates-operational-change-page-tsx": () => import("./../../../src/templates/operational-change-page.tsx" /* webpackChunkName: "component---src-templates-operational-change-page-tsx" */),
  "component---src-templates-operational-changes-overview-page-tsx": () => import("./../../../src/templates/operational-changes-overview-page.tsx" /* webpackChunkName: "component---src-templates-operational-changes-overview-page-tsx" */),
  "component---src-templates-page-with-navigation-tsx": () => import("./../../../src/templates/page-with-navigation.tsx" /* webpackChunkName: "component---src-templates-page-with-navigation-tsx" */),
  "component---src-templates-route-page-tsx": () => import("./../../../src/templates/route-page.tsx" /* webpackChunkName: "component---src-templates-route-page-tsx" */),
  "component---src-templates-routes-page-tsx": () => import("./../../../src/templates/routes-page.tsx" /* webpackChunkName: "component---src-templates-routes-page-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../../../src/templates/standard-page.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */)
}

