import { IPage } from '../types/heartcore.types';

export const isBrowser = typeof window !== 'undefined';

export const dateFormatter = (dateString: string, locale?: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString(locale || 'da-DK', { day: '2-digit', month: 'long', year: 'numeric' });
};

export const htmlRaw = (content: string) => {
	console.log(content);
	return {__html: content};
};

export const getMetaData = (page: IPage) => {
	return {
		language: page.lang,
		title: page.documentTitle || page.name,
		description: page.description,
		keywords: page.keywords,
		ogTitle: page.ogTitle,
		ogDescription: page.ogDescription,
		ogImage: page.ogImage, 
		ogUrl: page.ogUrl,
		ogType: page.ogType,
	};
};

export const timeout = (time: number) => {
	return new Promise((resolve) => setTimeout(resolve, time));
};


export const dateIsSet = (date) => {
	return date === '0001-01-01T00:00:00' ? null : date;
};