import gsap from 'gsap';
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink';
import React, { useContext } from 'react';
import LokaltogContext from '../context/context';
import { getCleanUrl } from '../utils/shared-build-runtime-utils';

type LinkWithPageTransitionProps = {
	url: string;
	external?: boolean;
	target?: string;
	classes?: string;
	activeClass?: string;
	children: React.ReactNode
}

const LinkWithPageTransition = ({ url, children, target='', classes='', activeClass='', external = false }: LinkWithPageTransitionProps) => {
	const context = useContext(LokaltogContext);
	const cleanedUrl = !external ? getCleanUrl(url, context.rootNodeUrl) : url;

	return (!external && target !== '_blank' ?
		<TransitionLink 
			to={cleanedUrl}
			target={target}
			className={classes}
			activeClassName={activeClass}
			exit={{
				zIndex: 1,
				length: .4,
			}}
			entry={{
				zIndex: 0,
				length: .4,
			}}
			trigger={async pages => {
				const entry = await pages.entry;
				const exit = await pages.exit;
				gsap.from(entry.node, { opacity: 0, duration: .1});
				gsap.to(exit.node, { opacity: 0, duration: .4});
				await entry.visible;
			}}
		>{children}</TransitionLink>
		: 
		<a className={classes} href={cleanedUrl} target={target}>{children}</a>
	);
};

export default LinkWithPageTransition;
