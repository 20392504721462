const getContent = (obj) => {
	return obj._embedded && obj._embedded.content;
};

/* currently Heartcore doesn't support the overwrite of the root node url to '/', so we need to clean it up manually */
const getCleanUrl = (path, rootNodeUrl) => {
	if(!path) { return '/'; }
	if(!path.includes(rootNodeUrl)) { return path; }
	const index = path.indexOf(rootNodeUrl);
	const pathWithoutRootNode = path.substring(index - 1 + rootNodeUrl.length);
	return pathWithoutRootNode;
};

module.exports = {
	getContent: getContent,
	getCleanUrl: getCleanUrl,
};
