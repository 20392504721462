import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { useEffect } from 'react';

export type LokaltogContextData = {
	rootNodeUrl: string;
	windowWidth: number;
	tr: (key: string) => string;
}

export const defaultContextValue: LokaltogContextData = {
	rootNodeUrl:'',
	windowWidth: 0,
	tr: (_key) => { return ''; },
};

const LokaltogContext = React.createContext(defaultContextValue);

export const LokaltogContextProviderComponent = ({ children }: { children:React.ReactNode }) => {
	const { data } = useStaticQuery(graphql`
		query {
			data: globalContent {
				rootNodeUrl
				dictionary {
					keyword
					translation
				}
			}
		}
	`);
	const translations = data?.dictionary;

	useEffect(() => {
		setState(prev => ({...prev, windowWidth: window.innerWidth}));

		let timeoutId = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setState(prev => ({...prev, windowWidth: window.innerWidth})), 10);
		};
		
		window.addEventListener('resize', resizeListener);
	
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	const tr = (key) => {
		if(!translations) { return key; }
		const entry = translations.find(e => e.keyword === key);
		return entry.translation ?? key;
	};

	const contextValue = {
		...defaultContextValue,
		rootNodeUrl: data?.rootNodeUrl,
		tr: tr,
	};

	const [ state, setState ] = useState<LokaltogContextData>(contextValue); 
	return <LokaltogContext.Provider value={state}>{children}</LokaltogContext.Provider>;
};

export default LokaltogContext;