import './src/scss/index.scss';
import React from 'react';
import { LokaltogContextProviderComponent } from './src/context/context';

// export const onServiceWorkerUpdateReady = () => { window.location.reload(); };

export const wrapRootElement = ({ element }) => (
	<LokaltogContextProviderComponent>{element}</LokaltogContextProviderComponent>
);

// https://support.cookieinformation.com/en/articles/5411279-google-consent-mode-implementation
const initGTM = () => {
	window.dataLayer = window.dataLayer || [];
	
	function gtag() {
		// eslint-disable-next-line no-undef
		dataLayer.push(arguments);
	}
	gtag('consent', 'default', {
		ad_personalization: 'denied',
		ad_storage: 'denied',
		ad_user_data: 'denied',
		analytics_storage: 'denied',
		functionality_storage: 'denied',
		personaliztion_storage: 'denied',
		wait_for_update: 15000,
	});
	gtag('set', 'ads_data_redaction', true);
	gtag('js', new Date());
	gtag('config', process.env.GA_TRACKING_ID);
};

export const onClientEntry = () => {
	initGTM();
};
