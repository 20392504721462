import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import LinkWithPageTransition from './link-with-page-transition';

const Footer = () => {
	const { data } = useStaticQuery(graphql`
		query {
			data: globalContent {
				footer {
					listOfLinks {
						listOfSectionChildrenLinks {
							name
							udi
							url
							target
						}
						listOfSectionChildrenLinksWithIcons {
							icon {
								altText
								url
							}
							link {
								name
								udi
								url
								target
							}
						}
						sectionLink {
							name
							udi
							url
							target
						}
					}
				}
			}
		}
	`);

	if(!data) { return <></>; }

	const { footer: { listOfLinks }} = data;

	return (
		<footer className='footer'>
			<nav className='footer__inner-wrapper box-wide'>
				{listOfLinks?.map(lli => lli.sectionLink &&
					<ul key={lli.sectionLink.udi} className='footer__section'>
						<li className='footer__navigation-item footer__navigation-item--enhanced'><LinkWithPageTransition classes='link-with-underline link-with-underline--white' url={lli.sectionLink.url} external={!lli.sectionLink.udi} target={lli.sectionLink.target}>{lli.sectionLink.name}</LinkWithPageTransition></li>
						{lli.listOfSectionChildrenLinks.map(link => <li key={link.udi} className='footer__navigation-item'><LinkWithPageTransition classes='link-with-underline link-with-underline--white' url={link.url} external={!link.udi} target={link.target}>{link.name}</LinkWithPageTransition></li>)}
						{lli.listOfSectionChildrenLinksWithIcons.map(linkWithIcon => 
							linkWithIcon.link && <li key={linkWithIcon.link.udi} className='footer__navigation-item footer__navigation-item--with-icon'>
								<LinkWithPageTransition url={linkWithIcon.link.url} external={!linkWithIcon.link.udi} target={linkWithIcon.link.target}>
									<img src={linkWithIcon.icon.url} alt='icon' />
								</LinkWithPageTransition>
							</li>)}
					</ul>)}
			</nav>
		</footer>
	);
};

export default Footer;
