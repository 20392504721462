import React, { useEffect } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';

type LayoutProps = {
	children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {

	useEffect(() => {
		window.addEventListener('CookieInformationConsentGiven', () => {
			if(!CookieInformation.getConsentGivenFor('cookie_cat_functional')
			&& !CookieInformation.getConsentGivenFor('cookie_cat_statistic')
			&& !CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
				window[`ga-disable-${process.env.GA_TRACKING_ID}`] = true;
				return;
			}

			// if (CookieInformation.getConsentGivenFor('cookie_cat_functional')) {}
			if (CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
				gtag('consent', 'update', {
					'analytics_storage': 'granted',
				});
			}
			if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
				gtag('consent', 'update', {
					'ad_storage': 'granted',
				});
			}
		}, false);
	}, []);

	return (
		<div className='content-wrapper'>
			<main className='main'>
				{children}
			</main>
			<Footer/>
			<Header/>
		</div>
	);
};

export default Layout;